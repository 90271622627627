<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <router-view />
  </div>
  <!--end::Basic info-->
</template>

<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();

    store.commit("SET_ACTIVE_MENU", "ktanisasi");
  },
};
</script>
